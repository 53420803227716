import React from "react";
import "./Header.css";

export default function Header() {
  return (
    <div className="hero">
      <h1>getInvolved</h1>
    </div>
  );
}
